<template>
	<div class="container">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">抽盲盒</div>
		</div>
		<div class="box-num">盲盒剩余数量:{{detail.surplusQuantity}}个</div>
		<div class="box-name">{{detail.boxName}}</div>
		<div class="box-item-wrap flex-row align-center flex-wrap"
			v-if="detail.categoryCount == 6 || detail.categoryCount == 7|| detail.categoryCount == 8||detail.categoryCount == 9">
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con">1</div>
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con">2</div>
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con">3</div>
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con">4</div>
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con">5</div>
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con">6</div>
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con" v-if="detail.categoryCount>=7">7</div>
				<img class="icon-num-bg" v-else src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con" v-if="detail.categoryCount>=8">8</div>
				<img class="icon-num-bg" v-else src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con" v-if="detail.categoryCount==9">9</div>
				<img class="icon-num-bg" v-else src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
		</div>
		<div class="box-item-wrap flex-row align-center flex-wrap" v-if="detail.categoryCount == 1">
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con">1</div>
			</div>
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
		</div>
		<div class="box-item-wrap flex-row align-center flex-wrap" v-if="detail.categoryCount == 2 || detail.categoryCount == 3">
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con">1</div>
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con">2</div>
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con" v-if="detail.categoryCount == 3">3</div>
				<img class="icon-num-bg" v-else src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
		</div>

		<div class="box-item-wrap flex-row align-center flex-wrap" v-if="detail.categoryCount == 4 ||detail.categoryCount == 5 ">
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con">1</div>
			</div>
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con">2</div>
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con">3</div>
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con">4</div>
			</div>
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<div class="box-item-con" v-if="detail.categoryCount == 5">5</div>
				<img class="icon-num-bg" v-else src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
			<div class="box-item flex-row align-center justify-center">
				<img class="icon-num-bg" src="../../assets/points/icon-num-bg2.png" alt="">
			</div>
		</div>
		<div class="box-desc flex-row align-center justify-center">{{detail.boxTitle}}</div>
		<div class="desc-wrap">
			<div class="desc-ti">盲盒详情</div>
			<div class="c-wrap" v-html="detail.summary"></div>
		</div>
		<div class="ch-wrap flex-row align-center space-between">
			<div class="ch-left">盲盒：{{detail.points}}积分</div>
			<div class="ch-right flex-row align-center justify-center">
				<div class="ch-btn-wrap flex-row align-center justify-center" @click.stop="buy()">
					<div class="ch-btn">兑换</div>
				</div>
			</div>
		</div>

		<div class="model" v-if="dhModal"></div>
		<div class="dh-modal" v-if="dhModal">
			<div class="dh-tips">《{{detail.boxName}}》盲盒兑换需</div>
			<div class="dh-points">{{detail.points}}积分</div>
			<div class="dh-line1"></div>
			<div class="dh-tips2">您的账户现有</div>
			<div class="dh-user-points">{{points}}积分</div>
			<div class="dh-line2"></div>
			<div class="dh-xy" @click.stop="navAgreement()">点击兑换默认拆开盲盒并同意《用户协议》</div>
			<div class="dh-btn-wrap flex-row align-center space-between">
				<div class="dh-btn-w flex-row align-center justify-center" @click.stop="closeDhModal()">
					<div class="dh-btn-t">取消</div>
				</div>
				<div class="dh-btn-w flex-row align-center justify-center" @click.stop="confirmPay()">
					<div class="dh-btn-t">确认</div>
				</div>
			</div>
		</div>
		<div class="model" v-if="resModal" @click.stop="closeResModal()"></div>
		<div class="res-modal" v-if="resModal">
			<div class="res-con">
				<div class="res-goods">抽中{{resData.commissionName}}</div>
				<div class="res-tips">提现手续费满{{resData.limitAmount}}可用</div>
				<div class="res-tips">有效期至{{resData.formatTime}}</div>
				<div class="res-tips2">可在“我的”--“红包卡券”中查看</div>

			</div>
			<div class="res-btn-wrap flex-row align-center space-between">
				<div class="res-btn-w flex-row align-center justify-center" @click.stop="buy()">
					<div class="res-btn-t">再抽一次</div>
				</div>
				<div class="res-btn-w flex-row align-center justify-center" @click.stop="navCard()">
					<div class="res-btn-t">查看详情</div>
				</div>
			</div>
		</div>
		<div class="model" v-if="resModal2" @click.stop="closeResModal2()"></div>
		<div class="res-modal2" v-if="resModal2">
			<div class="res-con2" v-if="detail.boxType ==2">
				<div class="res-goods2">抽中{{resData.name}}</div>
				<img :src="resData.mianPic" class="res-goods-img" alt="">
			</div>
			<div class="res-con2" v-if="detail.boxType ==3">
				<div class="res-goods2">抽中{{resData.materialName}}</div>
				<img :src="resData.listViewPic" class="res-goods-img" alt="">
			</div>
			<div class="res-btn-wrap flex-row align-center space-between">
				<div class="res-btn-w flex-row align-center justify-center" @click.stop="buy()">
					<div class="res-btn-t">再抽一次</div>
				</div>
				<div class="res-btn-w flex-row align-center justify-center" @click.stop="navCollect()">
					<div class="res-btn-t">查看详情</div>
				</div>
			</div>
		</div>
		<div class="model" v-if="addressModal" @click.stop="closeAddressModal()"></div>
		<div class="address-modal" v-if="addressModal">
			<div class="am-title-wrap flex-row justify-center align-center">
				<div class="am-title">填写地址</div>
				<div class="am-save-btn "
					:class="(!editUserAddress.name || !editUserAddress.address || !editUserAddress.phone || !editUserAddress.desc || !editUserAddress.code)?'am-save-btn-false':''"
					@click.stop="saveAddress()">保存</div>
			</div>
			<div class="am-item flex-row align-center">
				<div class="am-item-ti">联系人</div>
				<input type="text" v-model="editUserAddress.name" placeholder="联系人" class="am-item-input">
			</div>
			<div class="am-item flex-row align-center">
				<div class="am-item-ti">手机号码</div>
				<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
					v-model="editUserAddress.phone" placeholder="手机号码" class="am-item-input">
			</div>
			<div class="am-item flex-row align-center" @click="editCity()">
				<div class="am-item-ti">地址信息</div>
				<input type="text" v-model="editUserAddress.address" disabled placeholder="地址信息" class="am-item-input">
			</div>
			<div class="am-item flex-row align-center">
				<div class="am-item-ti">详细地址</div>
				<input type="text" v-model="editUserAddress.desc" placeholder="详细地址" class="am-item-input">
			</div>
			<div class="am-item flex-row align-center">
				<div class="am-item-ti">邮政编码</div>
				<input type="text" v-model="editUserAddress.code" placeholder="邮政编码" class="am-item-input">
			</div>
		</div>
		<div class="model" v-if="chooseAddressModal" @click.stop="closeChooseAddressModal()"></div>
		<div class="choose-address-modal" v-if="chooseAddressModal">
			<div class="am-title-wrap flex-row justify-center align-center">
				<div class="am-title">选择地区</div>
			</div>
			<div class="ca-con-wrap">
				<div class="flex-row align-center choose-con flex-wrap " v-if="areaArr.provinceIndex>=0">
					<div class="choose-con-item" @click.stop="changeAreaStep(1)">
						{{areaArr.provinceIndex>=0?areaArr.province[areaArr.provinceIndex].name:''}}
					</div>
					<div class="choose-con-item" @click.stop="changeAreaStep(2)"
						:class="areaArr.provinceIndex>=0 &&areaArr.cityIndex ==-1?'choose-con-item-false':''">
						{{areaArr.provinceIndex>=0 &&areaArr.cityIndex ==-1?'市':areaArr.city[areaArr.cityIndex].name}}
					</div>
					<div class="choose-con-item" v-if="areaArr.cityIndex>=0" @click.stop="changeAreaStep(3)"
						:class="areaArr.cityIndex>=0 &&areaArr.areaIndex ==-1?'choose-con-item-false':''">
						{{areaArr.cityIndex>=0 &&areaArr.areaIndex ==-1?'区/县':areaArr.area[areaArr.areaIndex].name}}
					</div>
					<div class="choose-con-item" v-if="areaArr.areaIndex>=0" @click.stop="changeAreaStep(4)"
						:class="areaArr.areaIndex>=0 &&areaArr.streetIndex ==-1?'choose-con-item-false':''">
						{{areaArr.areaIndex>=0 &&areaArr.streetIndex ==-1?'街道/镇':areaArr.street[areaArr.streetIndex].name}}
					</div>
				</div>
				<div class="addr-con " :class="areaArr.provinceIndex<0?'addr-con2':''" v-if="areaArr.step==1">
					<div class="addr-con-item flex-row align-center space-between"
						v-for="(item,index) in areaArr.province" :key="index" @click.stop="chooseProvice(index)">
						<div>{{item.name}}</div>
						<img v-if="index== areaArr.provinceIndex" src="../../assets/points/icon-choose.png"
							class="icon-choose" alt="">
					</div>
				</div>
				<div class="addr-con " :class="areaArr.provinceIndex<0?'addr-con2':''" v-if="areaArr.step==2">
					<div class="addr-con-item flex-row align-center space-between" v-for="(item,index) in areaArr.city"
						:key="index" @click.stop="chooseCity(index)">
						<div>{{item.name}}</div>
						<img v-if="index== areaArr.cityIndex" src="../../assets/points/icon-choose.png"
							class="icon-choose" alt="">
					</div>
				</div>
				<div class="addr-con " :class="areaArr.provinceIndex<0?'addr-con2':''" v-if="areaArr.step==3">
					<div class="addr-con-item flex-row align-center space-between" v-for="(item,index) in areaArr.area"
						:key="index" @click.stop="chooseArea(index)">
						<div>{{item.name}}</div>
						<img v-if="index== areaArr.areaIndex" src="../../assets/points/icon-choose.png"
							class="icon-choose" alt="">
					</div>
				</div>
				<div class="addr-con " :class="areaArr.provinceIndex<0?'addr-con2':''" v-if="areaArr.step==4">
					<div class="addr-con-item flex-row align-center space-between"
						v-for="(item,index) in areaArr.street" :key="index" @click.stop="chooseStreet(index)">
						<div>{{item.name}}</div>
						<img v-if="index== areaArr.streetIndex" src="../../assets/points/icon-choose.png"
							class="icon-choose" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="model" v-if="dhModal3"></div>
		<div class="dh-modal3 flex-column" v-if="dhModal3">
			<div class="dh-m-header flex-row align-center justify-center">
				<div class="dh-m-header-ti">我的地址</div>
				<div class="dh-m-header-edit" @click.stop="editAddr()">修改地址</div>
			</div>
			<div class="dh-m-center flex-column">
				<div class="dh-addr-info" v-if="userAddress.addressPhone">
					<div class="ah-a-h flex-row align-center">
						<div class="dh-a-h-ti">{{userAddress.addressName}}</div>
						<div class="dh-a-h-ph">{{userAddress.addressPhone}}</div>
					</div>
					<div class="ah-ad-h-c">
						{{userAddress.province}}{{userAddress.city}}{{userAddress.area}}{{userAddress.street}}{{userAddress.address}}
					</div>
				</div>
				<div class="dh-tips mt0">《{{detail.boxName}}》盲盒兑换需</div>
				<div class="dh-points">{{detail.points}}积分</div>
				<div class="dh-line1"></div>
				<div class="dh-tips2">您的账户现有</div>
				<div class="dh-user-points">{{points}}积分</div>
				<div class="dh-line2"></div>
				<div class="dh-xy" @click.stop="navAgreement()">点击兑换默认拆开盲盒并同意《用户协议》</div>
				<div class="dh-btn-wrap flex-row align-center space-between">
					<div class="dh-btn-w flex-row align-center justify-center" @click.stop="closeDhModal3()">
						<div class="dh-btn-t">取消</div>
					</div>
					<div class="dh-btn-w flex-row align-center justify-center" @click.stop="confirmPay()">
						<div class="dh-btn-t">确认</div>
					</div>
				</div>
			</div>
			<div class="dh-m-footer flex-row"></div>
		</div>
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	import config from '../../config/config.js';
	import util from '../../config/util.js';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				dhModal: 0,
				resModal: 0,
				resData: {},
				boxId: '',
				detail: {},
				points: '',
				addressModal: 0,
				chooseAddressModal: 0,
				resModal2: 0,
				dhModal3: 0,
				userAddress: {

				}, //用户地址
				editUserAddress: {
					name: '',
					phone: '',
					address: '',
					desc: '',
					code: ''
				},
				areaArr: {
					province: [],
					provinceIndex: -1,
					city: [],
					cityIndex: -1,
					area: [],
					areaIndex: -1,
					street: [],
					streetIndex: -1,
					step: 1
				}
			}
		},
		methods: {
			saveAddress() {
				const that = this;
				console.log(that.areaArr)
				if (!that.editUserAddress.name) {
					Toast('请输入姓名');
					return;
				}
				if (!that.editUserAddress.phone) {
					Toast('请输入手机号码');
					return;
				}
				if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(that.editUserAddress.phone))) {
					Toast('请输入正确的手机号')
					return;
				}
				if (!that.editUserAddress.address) {
					Toast('请输入地址信息');
					return;
				}
				if (!that.editUserAddress.desc) {
					Toast('请输入详细地址');
					return;
				}
				if (!that.editUserAddress.code) {
					Toast('请输入邮政编码');
					return;
				}
				let data = {
					addressName: that.editUserAddress.name,
					address: that.editUserAddress.desc,
					addressPhone: that.editUserAddress.phone,
					provinceId: that.areaArr.province[that.areaArr.provinceIndex].id,
					cityId: that.areaArr.city[that.areaArr.cityIndex].id,
					areaId: that.areaArr.area[that.areaArr.areaIndex].id,
					streetId: that.areaArr.street[that.areaArr.streetIndex].id,
					zip: that.editUserAddress.code,
				}
				let url = '/user-api/rest/userAddress/insertUserAddress'
				if (that.userAddress.addressPhone) {
					data.userAddressId = that.userAddress.userAddressId;
					url = '/user-api/rest/userAddress/updateUserAddress'
				}
				that.axios.post(config.pointsRequest +
					url,
					data, {
						headers: {
							'Content-Type': 'application/json',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						Toast('保存成功')
						that.selectUserAddress();
						that.addressModal = 0;
						that.buy();
					} else {
						that.error = true
						Toast(response.data.msg);
					}
				}, response => {
					that.error = true
					Toast('获取失败，请稍后重试');
				})
			},
			closeDhModal3() {
				this.dhModal3 = 0;
			},
			changeAreaStep(step) {
				if (step != this.areaArr) {
					if (step == 2) {
						if (this.areaArr.provinceIndex >= 0) {
							this.areaArr.step = 2;
						}
					} else if (step == 3) {
						if (this.areaArr.cityIndex >= 0) {
							this.areaArr.step = 3;
						}
					} else if (step == 4) {
						if (this.areaArr.areaIndex >= 0) {
							this.areaArr.step = 4;
						}
					} else {
						this.areaArr.step = 1;
					}
				}
			},
			chooseProvice(index) {
				const that = this;
				this.areaList(this.areaArr.province[index].id, 2, (() => {
					if (that.areaArr.provinceIndex != index) {
						that.areaArr.cityIndex = -1;
						that.areaArr.areaIndex = -1;
						that.areaArr.streetIndex = -1
					}
					that.areaArr.provinceIndex = index;
					that.areaArr.step = 2;
				}))
			},
			chooseCity(index) {
				const that = this;
				this.areaList(this.areaArr.city[index].id, 3, (() => {
					if (that.areaArr.cityIndex != index) {
						that.areaArr.areaIndex = -1;
						that.areaArr.streetIndex = -1
					}
					that.areaArr.cityIndex = index;
					that.areaArr.step = 3;
				}))
			},
			chooseArea(index) {
				const that = this;
				this.areaList(this.areaArr.area[index].id, 4, (() => {
					if (that.areaArr.areaIndex != index) {
						that.areaArr.streetIndex = -1
					}
					that.areaArr.areaIndex = index;
					that.areaArr.step = 4;
				}))
			},
			chooseStreet(index) {
				const that = this;
				that.areaArr.streetIndex = index;
				this.chooseAddressModal = 0
				this.addressModal = 1;
				this.editUserAddress.address = '' + that.areaArr.province[that.areaArr.provinceIndex].name +
					that.areaArr.city[that.areaArr.cityIndex].name + that.areaArr.area[that.areaArr.areaIndex].name +
					that.areaArr.street[that.areaArr.streetIndex].name

			},
			closeAddressModal() {
				this.addressModal = 0
			},
			closeChooseAddressModal() {
				this.chooseAddressModal = 0
			},
			editCity() {
				this.addressModal = 0
				this.chooseAddressModal = 1;
				this.step = 1;
				this.areaList(100000, 1)
			},
			areaList(parentId, level, cb) {
				const that = this;
				that.axios.post(config.pointsRequest +
					`/base-api/rest/baseArea/selectList`,
					parentId, {
						headers: {
							'Content-Type': 'text/xml',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						switch (level) {
							case 1:
								that.areaArr.province = response.data.data;
								break;
							case 2:
								that.areaArr.city = response.data.data;
								break;
							case 3:
								that.areaArr.area = response.data.data;
								break;
							case 4:
								that.areaArr.street = response.data.data;
								break;
							default:
								break;
						}
						if (cb) cb();
					} else {
						that.error = true
						Toast(response.data.msg);
					}
				}, response => {
					that.error = true
					Toast('获取失败，请稍后重试');
				})
			},
			editAddr() {
				const that = this;
				if (this.userAddress.addressPhone && !this.editUserAddress.phone) {
					console.log(0)
					this.editUserAddress = {
						name: this.userAddress.addressName,
						phone: this.userAddress.addressPhone,
						address: this.userAddress.province + this.userAddress.city + this.userAddress.area + this
							.userAddress.street,
						desc: this.userAddress.address,
						code: this.userAddress.zip
					}
					this.areaList(100000, 1, (() => {
						for (let i in that.areaArr.province) {
							if (that.areaArr.province[i].id == that.userAddress.provinceId) {
								that.areaArr.provinceIndex = i;
								continue;
							}
						}
					}))
					this.areaList(this.userAddress.provinceId, 2, (() => {
						for (let i in that.areaArr.city) {
							if (that.areaArr.city[i].id == that.userAddress.cityId) {
								that.areaArr.cityIndex = i;
								continue;
							}
						}
					}))
					this.areaList(this.userAddress.cityId, 3, (() => {
						for (let i in that.areaArr.area) {
							if (that.areaArr.area[i].id == that.userAddress.areaId) {
								that.areaArr.areaIndex = i;
								continue;
							}
						}
					}))
					this.areaList(this.userAddress.areaId, 4, (() => {
						for (let i in that.areaArr.street) {
							if (that.areaArr.street[i].id == that.userAddress.streetId) {
								that.areaArr.streetIndex = i;
								continue;
							}
						}
					}))
					this.dhModal3 = 0;
					this.addressModal = 1;
				} else {
					this.dhModal3 = 0;
					this.addressModal = 1;
				}

			},
			closeResModal2() {
				that.resModal2 = 0
			},
			navCollect() {
				if (this.detail.boxType == 2) {
					this.$router.replace('/collect')
				} else if (this.detail.boxType == 3) {
					this.$router.push('/points/entryorder')
				}
			},
			navCard() {
				this.$router.push('/points/card')
			},
			confirmPay() {
				const that = this
				if (that.detail.boxType == 3) {
					if (!that.userAddress.addressPhone) {
						that.dhModal3 = 0;
						that.addressModal = 1;
						return;
					}
				}
				if (that.points < that.detail.points) {
					Toast('积分不足');
					return;
				}
				Toast.loading({
					message: '兑换中...',
					forbidClick: true,
					duration: 0,
				});
				//类型(1.手续费抵用券 2.藏品盲盒 3.实物盲盒 4.优先购盲盒)
				switch (that.detail.boxType) {
					case 1:
						that.axios.post(config.pointsRequest +
							`/point-api/rest/pointBoxCommissionEntry/drawBox`,
							that.boxId, {
								headers: {
									'Content-Type': 'text/xml',
									'token': that.$store.state.user.token
								}
							}).then(response => {
							if (response.data.code == 0) {
								Toast.clear();
								that.dhModal = 0;
								that.resModal = 1;
								that.resData = response.data.data;
								let dueTime = response.data.data.dueTime.replace(/-/g, "/");
								that.resData.formatTime = util.formatDate2(dueTime)
							} else {
								that.error = true
								Toast(response.data.msg);
							}
						}, response => {
							that.error = true
							Toast('获取失败，请稍后重试');
						})
						break;
					case 2:
						that.axios.post(config.pointsRequest +
							`/point-api/rest/pointBoxNftEntry/drawBox`,
							that.boxId, {
								headers: {
									'Content-Type': 'text/xml',
									'token': that.$store.state.user.token
								}
							}).then(response => {
							if (response.data.code == 0) {
								Toast.clear();
								that.dhModal = 0;
								that.resModal2 = 1;
								that.resData = response.data.data;
								// let dueTime = response.data.data.dueTime.replace(/-/g, "/");
								// that.resData.formatTime = util.formatDate2(dueTime)
							} else {
								that.error = true
								Toast(response.data.msg);
							}
						}, response => {
							that.error = true
							Toast('获取失败，请稍后重试');
						})
						break;
					case 3:
						that.axios.post(config.pointsRequest +
							`/point-api/rest/pointBoxMaterialEntry/drawBox`,
							that.boxId, {
								headers: {
									'Content-Type': 'text/xml',
									'token': that.$store.state.user.token
								}
							}).then(response => {
							if (response.data.code == 0) {
								Toast.clear();
								that.dhModal = 0;
								that.dhModal3 = 0;
								that.resModal2 = 1;
								that.resData = response.data.data;
								// let dueTime = response.data.data.dueTime.replace(/-/g, "/");
								// that.resData.formatTime = util.formatDate2(dueTime)
							} else {
								that.error = true
								Toast(response.data.msg);
							}
						}, response => {
							that.error = true
							Toast('获取失败，请稍后重试');
						})
						break;
					case 4:
						that.axios.post(config.pointsRequest +
							`/point-api/rest/pointBoxPriorEntry/drawBox`,
							that.boxId, {
								headers: {
									'Content-Type': 'text/xml',
									'token': that.$store.state.user.token
								}
							}).then(response => {
							if (response.data.code == 0) {
								Toast.clear();
								that.dhModal = 0;
								that.resModal = 1;
								that.resData = response.data.data;
								let dueTime = response.data.data.dueTime.replace(/-/g, "/");
								that.resData.formatTime = util.formatDate2(dueTime)
							} else {
								that.error = true
								Toast(response.data.msg);
							}
						}, response => {
							that.error = true
							Toast('获取失败，请稍后重试');
						})
						break;
					default:
						that.axios.post(config.pointsRequest +
							`/point-api/rest/pointBoxCommissionEntry/drawBox`,
							that.boxId, {
								headers: {
									'Content-Type': 'text/xml',
									'token': that.$store.state.user.token
								}
							}).then(response => {
							if (response.data.code == 0) {
								Toast.clear();
								that.dhModal = 0;
								that.resModal = 1;
								that.resData = response.data.data;
								let dueTime = response.data.data.dueTime.replace(/-/g, "/");
								that.resData.formatTime = util.formatDate2(dueTime)
							} else {
								that.error = true
								Toast(response.data.msg);
							}
						}, response => {
							that.error = true
							Toast('获取失败，请稍后重试');
						})
						break;
				}


			},
			navAgreement() {
				this.$router.push({
					path: "/webview",
					query: {
						type: 3
					}
				});
			},
			selectPointBoxInfoById() {
				const that = this
				that.axios.post(config.pointsRequest +
					`/point-api/rest/pointBoxInfo/selectPointBoxInfoById`,
					that.boxId, {
						headers: {
							'Content-Type': 'text/xml',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						that.detail = response.data.data;
						
					} else {
						that.error = true
						Toast(response.data.msg);
					}
				}, response => {
					that.error = true
					Toast('获取失败，请稍后重试');
				})
			},
			buy() {
				const that = this
				if (that.detail.boxType == 1 || that.detail.boxType == 2) {
					let params = new FormData()
					that.axios.post(config.pointsRequest + '/point-api/rest/pointUser/selectPointByUserId', params, {
						headers: {
							'Content-Type': 'application/json',
							'token': that.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							that.resModal = 0;
							that.resModal2 = 0;
							that.resData = {}
							that.points = response.data.data.points;
							that.dhModal = 1;
						} else {
							Toast(response.data.msg);
						}
					}, response => {
						Toast('获取失败，请稍后重试');
					})
				} else if (that.detail.boxType == 3) {
					let params = new FormData()
					that.axios.post(config.pointsRequest + '/point-api/rest/pointUser/selectPointByUserId', params, {
						headers: {
							'Content-Type': 'application/json',
							'token': that.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							that.resModal = 0;
							that.resModal2 = 0;
							that.resData = {}
							that.points = response.data.data.points;
							that.dhModal3 = 1;
						} else {
							Toast(response.data.msg);
						}
					}, response => {
						Toast('获取失败，请稍后重试');
					})
				}
			},
			closeDhModal() {
				this.dhModal = 0
			},
			closeResModal() {
				this.resModal = 0
			},
			selectUserAddress() {
				const that = this;
				let params = new FormData()
				that.axios.post(config.pointsRequest + '/user-api/rest/userAddress/selectUserAddress', '', {
					headers: {
						'Content-Type': 'application/json',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (response.data.data) {
							that.userAddress = response.data.data
						}
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			}
		},
		activated() {
			this.boxId = this.$route.query.id;
		},
		mounted() {
			this.selectUserAddress();
		},
		beforeRouteEnter(to, from, next) {
			if (from.name != 'pointscard') {
				next(that => {
					that.boxId = that.$route.query.id;
					that.detail = {};
					that.dhModal = 0;
					that.dhModal3 = 0;
					that.resModal = 0;
					that.resModal2 = 0;
					that.addressModal = 0;
					that.chooseAddressModal = 0;
					that.selectPointBoxInfoById();
				})
			} else {
				next(that => {
					that.resModal = 0;
					that.resModal2 = 0;
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		min-height: 100%;
		background: url(../../assets/points/bg.png);
		background-size: 750px 1755px;
		padding-bottom: 120px;
	}

	.box-num {
		width: 349px;
		height: 60px;
		margin: 37px auto 27px;
		background: #FFFFFF;
		border-radius: 40px;
		opacity: 0.39;
		text-align: center;
		line-height: 60px;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
	}

	.box-name {
		width: 590px;
		height: 130px;
		margin: auto;
		background: url(../../assets/points/name-bg.png);
		background-size: 590px 130px;
		line-height: 130px;
		text-align: center;
		font-size: 40px;
		font-weight: 600;
		color: #000000;
		letter-spacing: 10px;
	}

	.box-item-wrap {
		width: 586px;
		height: 586px;
		border: 5px solid #ffffff;
		margin: 34px auto 0;
		background: #ffffff;
		box-sizing: border-box;
		border-radius: 15px;
	}

	.box-item {
		width: 192px;
		height: 192px;
		background: #BFA88D;
		border: 1px solid #ffffff;
		box-sizing: border-box;
	}

	.box-item-con {
		width: 146px;
		height: 146px;
		text-align: center;
		line-height: 146px;
		background: url(../../assets/points/icon-num-bg.png);
		background-size: 146px 146px;
	}

	.icon-num-bg {
		width: 146px;
		height: 146px;
	}

	.box-desc {
		width: 590px;
		height: 173px;
		padding: 40px 20px 20px;
		box-sizing: border-box;
		margin: auto;
		background: url(../../assets/points/desc-bg.png);
		background-size: 590px 173px;
		font-size: 28px;
		font-weight: 600;
		color: #6C4C27;
	}

	.desc-wrap {
		width: 690px;
		min-height: 80px;
		padding-top: 60px;
		background: #FFFFFF;
		border-radius: 20px;
		border: 2px solid #AF9270;
		margin: 121px auto 20px;
		position: relative;
	}

	.desc-ti {
		position: absolute;
		z-index: 10;
		top: -43px;
		left: 141px;
		width: 409px;
		height: 87px;
		background: url(../../assets/points/name-bg.png);
		background-size: 409px 87px;
		font-size: 28px;
		font-weight: 600;
		color: #4E3517;
		line-height: 87px;
		letter-spacing: 4px;
		text-align: center;
	}

	.ch-wrap {
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 100;
		width: 750px;
		height: 98px;
		background: #FFFFFF;
		box-shadow: -1px 0px 0px 0px #FFFFFF;
		border-radius: 20px 20px 0 0;
		overflow: hidden;
	}

	.ch-left {
		width: 375px;
		height: 98px;
		font-size: 30px;
		font-weight: 600;
		line-height: 98px;
		text-align: center;
		color: #FFFFFF;
		background: #AF9270;
	}

	.ch-right {

		width: 373px;
		height: 98px;
		background: #AF9270;
	}

	.ch-btn-wrap {
		width: 210px;
		height: 74px;
		border-radius: 37px;
		border: 2px solid #FFFFFF;
		background: #AF9270;

	}

	.ch-btn {
		width: 190px;
		height: 56px;
		background: #FFFFFF;
		border-radius: 31px;
		font-size: 30px;
		font-weight: 600;
		color: #AF9270;
		text-align: center;
		line-height: 59px;
	}

	.dh-modal {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1001;
		margin: auto;
		width: 610px;
		height: 839px;
		background-image: url(../../assets/points/modal-bg.png);
		background-size: 610px 839px;
	}

	.dh-tips {
		margin-top: 94px;
		width: 610px;
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		text-align: center;
	}

	.dh-points {
		width: 296px;
		height: 77px;
		margin: 19px auto 0;
		background: #F3EEE5;
		border-radius: 39px;
		text-align: center;
		line-height: 77px;
		font-size: 30px;
		font-weight: 600;
		color: #937757;
	}

	.dh-line1 {
		width: 530px;
		height: 1px;
		background: rgba(0, 0, 0, 0.1000);
		margin: 70px auto 51px;
	}

	.dh-tips2 {
		width: 610px;
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		text-align: center;
	}

	.dh-user-points {
		width: 296px;
		height: 77px;
		margin: 19px auto 0;
		background: rgba(98, 54, 255, 0.0600);
		border-radius: 39px;
		text-align: center;
		line-height: 77px;
		font-size: 30px;
		font-weight: 600;
		color: #6236FF;
	}

	.dh-line2 {
		width: 595px;
		height: 2px;
		background: #B29574;
		margin: 60px auto 30px;
	}

	.dh-xy {
		width: max-content;
		padding: 11px 35px;
		margin: auto;
		background: linear-gradient(90deg, #FFFFFF 0%, #FDE9CC 50%, #FFFFFF 100%);
		border-radius: 25px;
		font-size: 20px;
		font-weight: 400;
		color: #FA6400;
	}

	.dh-btn-wrap {
		width: 450px;
		margin: 56px auto 0
	}

	.dh-btn-w {
		width: 210px;
		height: 74px;
		border-radius: 37px;
		border: 2px solid #B29574;
	}

	.dh-btn-t {
		width: 190px;
		height: 56px;
		text-align: center;
		line-height: 56px;
		background: #B29574;
		border-radius: 31px;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
	}

	.res-modal {
		position: fixed;
		top: 94px;
		left: 0;
		right: 0;
		z-index: 1001;
		margin: auto;
		width: 548px;
		height: max-content;
		max-height: 100%;
		overflow: scroll;
	}

	.res-con {
		width: 548px;
		height: 1010px;
		background-image: url(../../assets/points/res-bg.png);
		background-size: 548px 1010px;
		overflow: hidden;
	}

	.res-goods {
		margin-top: 481px;
		margin-bottom: 28px;
		width: 548px;
		text-align: center;
		font-size: 30px;
		font-weight: 500;
		color: #000000;
	}

	.res-tips {
		width: 548px;
		text-align: center;
		font-size: 20px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5000);
	}

	.res-tips2 {
		width: 452px;
		height: 50px;
		margin: 27px auto 0;
		background: linear-gradient(90deg, #FFFFFF 0%, #FDE9CC 50%, #FFFFFF 100%);
		border-radius: 25px;
		text-align: center;
		line-height: 50px;
	}

	.res-btn-wrap {
		width: 450px;
		margin: 52px auto;
	}

	.res-btn-w {
		width: 210px;
		height: 74px;
		border-radius: 37px;
		border: 2px solid #B29574;
	}

	.res-btn-t {
		width: 190px;
		height: 56px;
		background: #B29574;
		border-radius: 31px;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 56px;
	}

	.c-wrap {
		width: 650px;
		padding: 0 20px;
	}

	.c-wrap>>>img {
		max-width: 100% !important;
		height: auto !important;
	}

	.address-modal {
		width: 750px;
		height: 776px;
		background: #ffffff;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1001;
	}

	.am-title-wrap {
		width: 640px;
		height: 88px;
		margin: auto;
		border-bottom: 1px solid #BFA88D;
		position: relative;
	}

	.am-title {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}

	.am-save-btn {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 10px;
		margin: auto;
		width: 100px;
		height: 50px;
		font-size: 26px;
		font-weight: 500;
		color: #FFFFFF;
		text-align: center;
		line-height: 50px;
		background: #BEA78C;
		color: #ffffff;
		border-radius: 8px;
	}

	.am-save-btn-false {
		background: rgba(0, 0, 0, 0.25);
	}

	.am-item {
		width: 650px;
		height: 110px;
		margin: auto;
		box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
	}

	.am-item-ti {
		width: 177px;
		font-size: 28px;
		font-weight: 600;
		color: #000000;
	}

	.am-item-input {
		width: 470px;
		font-size: 28px;
		border: none;
		background: initial;
	}


	.res-modal2 {
		position: fixed;
		top: 94px;
		left: 0;
		right: 0;
		z-index: 1001;
		margin: auto;
		width: 548px;
		height: max-content;
		max-height: 100%;
		overflow: scroll;
	}

	.res-con2 {
		width: 548px;
		height: 1010px;
		background-image: url(../../assets/points/res-bg2.png);
		background-size: 548px 1010px;
		overflow: hidden;
	}

	.res-goods2 {
		margin-top: 481px;
		margin-bottom: 28px;
		width: 548px;
		text-align: center;
		font-size: 30px;
		font-weight: 500;
		color: #000000;
	}

	.res-goods-img {
		width: 286px;
		height: 315px;
		display: block;
		margin: 34px auto 0;
	}

	.choose-address-modal {
		width: 750px;
		height: 776px;
		background: #ffffff;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1001;
	}

	.ca-con-wrap {
		width: 640px;
		margin: auto;
	}

	.choose-con {
		height: 100px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.choose-con-item {
		/* height: 100px; */
		margin-right: 40px;
		/* line-height: 100px; */
		font-size: 28px;
		font-weight: 600;
		color: #000000;

	}

	.choose-con-item:last-child {
		margin-right: 0;
	}

	.choose-con-item-false {
		color: rgba(0, 0, 0, 0.25);
	}

	.addr-con {
		height: 590px;
		overflow: scroll;
	}

	.addr-con-item {
		margin: auto;
		width: 640px;
		height: 100px;
		box-sizing: border-box;
		padding: 0 10px;
		background: #FFFFFF;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.addr-con2 {
		height: 690px;
	}

	.dh-modal3 {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1001;
		margin: auto;
		width: 610px;
		height: max-content;
		overflow: scroll;
		max-height: 90%;
	}

	.dh-m-center {
		width: 610px;
		height: max-content;
		background-image: url(../../assets/points/modal-bg2.png);
		background-size: 610px 32px;
		background-repeat: repeat-y;

	}

	.dh-m-footer {
		width: 610px;
		height: 90px;
		background-image: url(../../assets/points/modal-bg.png);
		background-size: 610px 839px;
		background-position: 0 90px;

	}

	.dh-m-header {
		width: 611px;
		height: 122px;
		background-image: url(../../assets/points/modal-bg.png);
		background-size: 610px 839px;
		/* background-position: 0 90px; */

	}

	.dh-m-header-ti {
		font-size: 26px;
		font-weight: 600;
		color: #B29574;
	}

	.dh-m-header-edit {
		padding: 6px 11px;
		background: #F3EEE5;
		border-radius: 18px;
		font-size: 16px;
		font-weight: 400;
		color: #866B4B;
		position: absolute;
		top: 40px;
		right: 57px;
	}

	.dh-addr-info {
		width: 408px;
		padding: 0 0 46px 0;
		margin: auto;
	}

	.dh-a-h-ti {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		margin-right: 20px;
	}

	.dh-a-h-ph {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
	}

	.ah-ad-h-c {
		margin-top: 20px;
	}

	.mt0 {
		margin-top: 0;
	}

	.am-item-input-false {
		color: #cccccc;
	}

	.icon-choose {
		width: 38px;
		height: 38px;
	}
</style>
